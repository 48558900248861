import { Container } from "@material-ui/core"
import React from "react"
import { ButtonSecondary } from "../components/Buttons/Buttons"
import { Link } from "gatsby"

import { Layout } from "../components/layout"
import styled from "styled-components"

import CheckImage from "../assets/forms/round-tick.svg"

const SignedOut = ({ data, location }) => {
  return (
    <Layout title={"Graduate Fashion Week - Sign Out Sucessful"}>
      <Container maxWidth={"sm"}>
        <Wrapper>
          <Check>
            <CheckImage />
          </Check>
          <Message>You're now signed out</Message>
          <ButtonContainer>
            <ButtonSecondary to={"/events/login"}>Sign In</ButtonSecondary>
          </ButtonContainer>
          <Link to={"/"}>Go to homepage</Link>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default SignedOut

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 85px auto 158px;
`
const Check = styled.div`
  width: 100px;
  height: 100px;
  margin: 20px;
`
const Message = styled.h2`
  color: ${props => props.theme.colors.turquoise};
  margin-bottom: 58px;
`
const ButtonContainer = styled.div`
  margin-bottom: 15px;
`
